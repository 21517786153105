.checkout-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: flex-start; /* Align items to the start of the container */
    padding-top: 6vh; /* Adjust this value to lower the position */
    z-index: 10000;
    overflow-y: scroll;
    
}

.checkout-container {
    width: 60%;
    max-width: 600px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    margin-top: 0px; /* Add top margin for further adjustment */
    /* Remove 'top: 20' if present, as it's not valid without a position property */
    z-index: 10001;
}

.close-button {
    position: absolute;
    top: 0px; /* Adjust as needed for positioning */
    right: -600px; /* Adjust as needed for positioning */
    padding: 10px; /* Adjust padding for better click area */
    z-index: 10002;
  }

.compact-grid .MuiGrid-item {
    padding-top: 10px !important;
}